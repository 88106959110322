.warning-message {
    background-color: rgba(255, 255, 255, 0.25); /* Semi-transparent white background */
    /*color: #333; !* Text color *!*/
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Blurred effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}
