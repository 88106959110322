@media print {
    body {
        font-family: Arial, sans-serif;
        margin: 20px;
        line-height: 1.6;
    }

    h1, h2 {
        color: #2E86C1;
    }

    h3 {
        color: #2874A6;
        border-bottom: 1px solid #2874A6;
        padding-bottom: 5px;
    }

    h4 {
        color: #1F618D;
        margin-top: 18px;
    }

    p, ul {
        margin: 0 0 8px 0;
        padding: 0;
    }

    ul {
        list-style-type: disc;
        margin-left: 20px;
    }

    a {
        color: #2980B9;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}